import * as React from "react";

export interface Suggestions {
	artworks: any[];
	artists: ArtistSuggestion[];
}

interface ArtistSuggestion {
	key: string;
	doc_count: number;
}

interface Props {
	suggestions: Suggestions;
	onSuggestionSelect: OnSuggestionSelectCallback;
}

interface OnSuggestionSelectCallback {
	(key: string): void;
}

export default function (props: Props) {

	function getArtworkFragment() {
		if (props.suggestions.artworks.length > 0) {
			return (
				<div>
					<h2>Werke</h2>
					<ul>
						{props.suggestions.artworks.map((artwork: any) => {
							return (
								<li key={artwork.id}>
									<span onClick={() => props.onSuggestionSelect(artwork.title)}>{artwork.title}</span>
								</li>
							)
						})}
					</ul>
				</div>
			)
		} else {
			return null;
		}
	}

	function getArtistFragment() {

		if (props.suggestions.artists.length > 0) {
			return (
				<div>
					<h2>Künstler</h2>
					<ul>
						{props.suggestions.artists.map((artist: ArtistSuggestion) => {
							return (
								<li key={artist.key}>
									<span onClick={() => props.onSuggestionSelect(artist.key)}>{artist.key}</span>
								</li>
							)
						})}
					</ul>
				</div>
			)
		} else {
			return null
		}

	}

	return (
		<div className="suggestionList">
			{getArtworkFragment()}
			{getArtistFragment()}

		</div>
	);
}
