import Axios from 'axios';

export interface SearchResultItem {

}

export interface SearchResult {
	results: SearchResultItem[] | any[]; // TODO: Remove any
	buckets: any;
	meta: SearchMetaData;
}

export interface SearchMetaData {
	hits: number;
	pages: number;
	time: number;
}


export default class SearchService {

	private seed: number;

	constructor(private endpoint: string) {
		this.seed = Math.floor(Math.random() * 100);
	}

	public setId(id: number) {
		this.seed = id;
	}

	public search(query: string, filters: Map<string, Set<string>>, limit: number = 15, offset: number = 0): Promise<SearchResult> {
		const axios = Axios.create({});

		const filterBody: any = {};
		filters.forEach((values, filterName) => {
			filterBody[filterName] = Array.from(values);
		});

		let searchBody = {
			"query": query,
			"filters": filterBody
		}

		return axios.post(this.endpoint + '/search?id=' + this.seed + '&offset=' + offset + '&limit=' + limit, searchBody).then(response => response.data);
	}

	public suggest(query: string) {
		const axios = Axios.create({});

		return axios.post(this.endpoint + '/suggest', {query}).then(response => response.data);
	}
}
