import * as React from "react";
import {FormEvent} from "react";
import SearchService from "../services/SearchService";
import QueryBar, {OnSearchSubmittedCallback} from "./QueryBar";

export interface Props {
	searchService: SearchService
}

export interface State {
	query: string;
}

export default class SuggestApp extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			query: ''
		}
	}

	public loadSuggestions(query: string) {
		return this.props.searchService.suggest(query);
	}

	onSearchEvent: OnSearchSubmittedCallback = (query: string, event?: FormEvent<HTMLFormElement>) => {
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('query', query);

		window.location.href = '/suche?'+queryParams;
	}

	render() {
		return (
			<QueryBar onSubmit={this.onSearchEvent} loadSuggestions={(query) => this.loadSuggestions(query)} />
		)
	}
}
