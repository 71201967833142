import * as React from "react";
import {Filter, FilterValue} from "./App";

interface Props {
	filter: Filter,
	values: Set<FilterValue>,
	onFilterChange?: FilterChangesCallback
}

interface FilterChangesCallback {
	(value: string): void;
}


export default function FilterBucket(props: Props) {
	return (
		<div className="filterBucket">
			<h3>{props.filter.title}</h3>
			<ul>
				{Array.from(props.values).map(value => {
					return (
						<li key={value.value}>
							<label>
								<input className="srOnly" type="checkbox" name={"filters["+props.filter.id+"][]"} checked={value.checked} onChange={(event) => props.onFilterChange(value.value)} />
								<span>{value.label} ({value.count})</span>
							</label>
						</li>
					)
				})}
			</ul>
		</div>
	);
}




// import {Filter} from "./SearchBar";
//
// interface Props {
// 	filter: Filter
// 	onFilterChanges?: FilterChangesCallback
// }
//
// interface State {
// 	checked: Set<string>
// }
//
// interface FilterChangesCallback {
// 	(values: Set<string>): void;
// }
//
// export default class FilterBucket extends React.Component<Props, State> {
//
// 	constructor(props: Props) {
// 		super(props);
//
// 		/*this.state = {
// 			checked: new Set()
// 		};*/
// 	}
//
// 	reset() {
// 		this.setState({
// 			checked: new Set()
// 		}, () => {
// 			if (this.props.onFilterChanges !== undefined) {
// 				this.props.onFilterChanges(this.state.checked);
// 			}
// 		});
// 	}
//
// 	handleFilterChange(value: string) {
// 		/*if (this.state.checked.has(value)) {
// 			this.state.checked.delete(value);
// 		} else {
// 			this.state.checked.add(value);
// 		}
//
// 		this.setState({
// 			checked: this.state.checked
// 		});
//
// 		if (this.props.onFilterChanges !== undefined) {
// 			this.props.onFilterChanges(this.state.checked);
// 		}*/
// 	}
//
// 	render() {
// 		return (
// 			<div className="filterBucket">
// 				<h2>{this.props.filter.title}</h2>
// 				<ul>
// 					{this.props.filter.values.map(value => {
// 						return (<li key={value.value}><label><input type="checkbox" name={"filters["+this.props.filter.id+"][]"} checked={value.checked} onChange={(event) => this.handleFilterChange(value.value)} /><span>{value.label} ({value.count})</span></label></li>)
// 					})}
// 				</ul>
// 			</div>
// 		);
// 	}
// }
