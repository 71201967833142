import * as React from "react";
import {Filter, FilterChangesCallback} from "./App";

interface Props {
	filters: Map<Filter, Set<string>>;
	onFilterChange?: FilterChangesCallback;
}

export default function ActiveFilters(props: Props) {

	const activeFilters: any = [];
	props.filters.forEach((values, filter) => {
		values.forEach(value => {
			activeFilters.push({
				value,
				filter
			});
		});
	})

	const resetAll = function () {
		activeFilters.forEach((activeFilter:any) => {
			props.onFilterChange(activeFilter.value, activeFilter.filter);
		});
	}

	return (
		<div className="activeFilters">
			<ul>
				{activeFilters.map((activeFilter: any) => {
					return (<li key={activeFilter.filter.id+'|'+activeFilter.value} onClick={() => props.onFilterChange(activeFilter.value, activeFilter.filter)}>{activeFilter.value}</li>)
				})}
			</ul>

			<button className="filterButton-reset" onClick={resetAll}>Alle Filter zurücksetzen</button>
		</div>
	);
}
