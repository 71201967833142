import * as React from "react";
import {FormEvent} from "react";
import SearchService, {SearchResult} from "../services/SearchService";
import ResultsList from "./ResultsList";
import ActiveFilters from "./ActiveFilters";
import FilterBucket from "./FilterBucket";
import {Suggestions} from "./SuggestionsList";
import QueryBar, {OnSearchSubmittedCallback} from "./QueryBar";

export type Filters = Map<Filter, Set<FilterValue>>;

interface Props {
	searchService: SearchService;
}

interface State {
	filterBarOpened?: boolean;
	results: SearchResult;
	filters: Filters;
	query: string;
	offset: number;
	limit: number;
	seed: number;
	listResults: boolean;
}

export interface Filter {
	title: string;
	id: string;
}

export interface FilterValue {
	value: string;
	label: string;
	checked: boolean;
	count?: number;
}

export interface FilterChangesCallback {
	(value: string, filter: Filter): void;
}

class App extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		const queryParams = new URLSearchParams(window.location.search);
		const filters = new Map<Filter, Set<FilterValue>>();

		this.state = {
			filterBarOpened: false,
			results: null,
			filters: filters,
			query: queryParams.get('query') || null,
			offset: Math.max(0, Number(queryParams.get('offset') || 0)),
			limit: Math.min(50, Number(queryParams.get('limit') || 15)),
			seed: Number(localStorage.getItem('kunstsammlung_seed') || Math.floor(Math.random() * 100)),
			listResults: false,
		};

		localStorage.setItem('kunstsammlung_seed', this.state.seed.toString());
	}

	async componentDidMount() {
		this.props.searchService.setId(this.state.seed);
		await this.props.searchService.search('', new Map<string, Set<string>>(), 0, 0).then(results => {
			this.updateFilters(results.buckets);

			const queryParams = new URLSearchParams(window.location.search);
			Array.from(this.state.filters.keys()).forEach(filter => {
				if (queryParams.has('filters.'+filter.id)) {
					const checkedFilterValues = queryParams.get('filters.'+filter.id).split(',');
					checkedFilterValues.forEach(checkedValue => {
						this.state.filters.get(filter).forEach(v => {
							if (v.value == checkedValue) {
								v.checked = !v.checked;
							}
						});
					});
				}
			});
			this.setState({
				filters: this.state.filters
			});
		});

		for (let offset = 0; offset <= this.state.offset; offset = offset + this.state.limit) {
			await this.doSearch(this.state.query, this.state.filters, offset, this.state.limit);
		}
	}

	handleFilterChange(value: string, filter: Filter) {

		this.state.filters.get(filter).forEach(v => {
			if (v.value == value) {
				v.checked = !v.checked;
			}
		});

		this.setState({
			filters: this.state.filters,
			offset: 0, // Reset offset on filter change
		}, () => {
			this.doSearch(this.state.query, this.state.filters, this.state.offset, this.state.limit);
		});

	}

	handleLoadMore() {
		this.setState({
			offset: this.state.offset + this.state.limit
		}, () => {
			this.doSearch(this.state.query, this.state.filters, this.state.offset, this.state.limit);
		});
	}

	onSearchEvent: OnSearchSubmittedCallback = (query: string, event?: FormEvent<HTMLFormElement>) => {
		if (event) {
			event.preventDefault();
		}

		this.setState({
			query,
			offset: 0, // Reset offset on search event
		}, () => {
			this.doSearch(query, this.state.filters, this.state.offset);
		});
	}

	doSearch(query: string, filters: Filters, offset: number = 0, limit: number = 15) {
		const queryParams = new URLSearchParams(window.location.search);
		queryParams.set('limit', limit.toString());
		queryParams.set('offset', offset.toString());

		if (query != null) {
			queryParams.set('query', query);
		}

		const checkedFilters = this.getCheckedFilters(this.state.filters);
		const filterMap: Map<string, Set<string>> = new Map<string, Set<string>>();

		checkedFilters.forEach((values, filter) => {
			filterMap.set(filter.id, values);

			if (values.size > 0) {
				queryParams.set('filters.' + filter.id, Array.from(values).toString());
			} else if (queryParams.has('filters.' + filter.id)) {
				queryParams.delete('filters.' + filter.id);
			}
		});

		window.history.replaceState({query: query}, null, '?' + queryParams);

		return this.props.searchService.search(query || '', filterMap, limit, offset).then(results => {

			if (offset == 0) {
				this.updateFilters(results.buckets, filterMap);
			}

			if (offset > 0 && this.state.results != null) {
				this.state.results.results = this.state.results.results.concat(results.results);

				this.setState({
					results: this.state.results
				});

			} else {
				this.setState({
					results: results
				});
			}
		});
	}

	public loadSuggestions(query: string) {
		return this.props.searchService.suggest(query);
	}

	private updateFilters(buckets: any, filterMap?: Map<string, Set<string>>) {
		const tagFilterValues: Set<FilterValue> = new Set<FilterValue>();
		const tagFilterChecked: Set<string> = filterMap && filterMap.has('tags') ? filterMap.get('tags') : new Set<string>();

		buckets.tags.forEach((tag: any) => {
			tagFilterValues.add({
				value: tag.label,
				label: tag.label,
				checked: tagFilterChecked.has(tag.label),
				count: tag.count,
			});
		});

		const techniqueFilterValues: Set<FilterValue> = new Set<FilterValue>();
		const techniqueFilterChecked: Set<string> = filterMap && filterMap.has('technique') ? filterMap.get('technique') : new Set<string>();

		buckets.techniques.forEach((technique: any) => {
			techniqueFilterValues.add({
				value: technique.label,
				label: technique.label,
				checked: techniqueFilterChecked.has(technique.label),
				count: technique.count,
			});
		});

		const filters = this.state.filters;
		filters.clear();

		filters.set({
			title: 'Technik',
			id: 'technique',
		}, techniqueFilterValues);

		filters.set({
			title: 'Kategorie',
			id: 'tags',
		}, tagFilterValues);

		this.setState({
			filters: filters
		});
	}

	private getCheckedFilters(filters: Map<Filter, Set<FilterValue>>): Map<Filter, Set<string>> {
		const filterMap = new Map<Filter, Set<string>>();
		if (filters !== undefined) {
			filters.forEach((values, filter) => {
				const checkedFilters = new Set(Array.from(values).filter(value => {
					return value.checked;
				}).map(value => {
					return value.value;
				}));

				filterMap.set(filter, checkedFilters);
			});
		}
		return filterMap;
	}

	getFilterFragment() {
		const filterBuckets = [];

		for (let value of Array.from(this.state.filters)) {
			filterBuckets.push(this.getFilterBucketFragment(value[0], value[1]));
		}

		return (
			<div>
				<button className="filterButton" aria-haspopup="true" aria-controls="filterBar" onClick={() => this.handleFilterBarToggle()}><span className="icon-filter" aria-hidden="true"></span>Filter öffnen</button>

				<div className={"filterBar " + (this.state.filterBarOpened ? 'opened' : 'closed')} id="filterBar">
					<button className="filterButtonClose" aria-controls="filterBar" onClick={() => this.handleFilterBarToggle()}>Filter schließen</button>

					<div className="wrapper">
						<h2><span className="icon-filter" aria-hidden="true"></span>Filter</h2>
						{filterBuckets}
					</div>

				</div>
				<button className="filterButton-bodyTrigger" aria-controls="filterBar" onClick={() => this.handleFilterBarToggle()}>Filter schließen</button>
			</div>
		)
	}
	getFilterBucketFragment(filter: Filter, values: Set<FilterValue>) {
		if (values.size > 0) {
			return (
				<FilterBucket key={filter.id} filter={filter} values={values} onFilterChange={(value) => this.handleFilterChange(value, filter)} />
			);
		} else {
			return null;
		}
	}

	getResultSwitch(){
		return (
			<div className="results-switch" role="radiogroup" aria-label="Ergebnis-Ansicht wechseln">
				<button className="results-switch-boxes" aria-label="Kachel-Ansicht" role="radio" aria-checked={this.state.listResults ? "false" : "true"} onClick={() => this.setState({listResults : false})}><span className="icon-boxes"></span></button>
				<button className="results-switch-list" aria-label="Listen-Ansicht" role="radio" aria-checked={this.state.listResults ? "true" : "false"} onClick={() => this.setState({listResults : true})}><span className="icon-list"></span></button>
			</div>
		)
	}

	handleFilterBarToggle() {
		this.setState({
			filterBarOpened: !this.state.filterBarOpened
		});
	}

	render() {
		const checkedFilters = this.getCheckedFilters(this.state.filters);
		const isFilteredSearch = [].concat.apply([], Array.from(checkedFilters.values()).map(set => Array.from(set))).length > 0;

		const resultsList = (this.state.results !== null) ? <ResultsList listResults={this.state.listResults} results={this.state.results.results} meta={this.state.results.meta} onLoadMore={this.handleLoadMore.bind(this)} /> : null;
		const statistics = (this.state.results !== null) ? <span>{this.state.results.meta.hits} {this.state.results.meta.hits != 1 ? 'Werke' : 'Werk'}</span> : null;
		const activeFilters = (isFilteredSearch) ? <ActiveFilters filters={checkedFilters} onFilterChange={this.handleFilterChange.bind(this)} /> : null;

		return (

			<div className="wrapper bg-dark">

				<section className="content-section centered-wide">
					<header className="headlineArea headlineArea-main master-padding-wide">
						<h1>Alle <span>Werke</span></h1>
					</header>

					<div className="wrapper master-padding cProject_customList main-content">

						<div className="artworkSearch">
							<div className="searchBar content-section-flex">
								<QueryBar query={this.state.query} onSubmit={this.onSearchEvent} loadSuggestions={(query) => this.loadSuggestions(query)} />

								{this.getFilterFragment()}

								{this.getResultSwitch()}
							</div>

							<div className="search-tags">
								{statistics}
								{activeFilters}
							</div>

							{resultsList}
						</div>
					</div>
				</section>

			</div>
		)
	}
}

export default App;
