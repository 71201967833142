import * as React from "react";
import Result from "./Result";
import {SearchMetaData} from "../services/SearchService";

interface Props {
	results: any[]; // TODO: Type
	meta: SearchMetaData;
	onLoadMore?: onLoadMoreCallback;
	listResults: boolean
}

export interface onLoadMoreCallback {
	(): void;
}


export default class ResultsList extends React.Component<Props, any> {


	componentDidMount() {
		this.dispatchEvent();
	}

	handleLoadMore() {
		if (this.props.onLoadMore !== undefined) {
			this.props.onLoadMore();
		}
	}

	componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any) {
		this.dispatchEvent();
	}


	private dispatchEvent() {

		const event = new CustomEvent('artwork_search_results_loaded', {detail:
				{listResults: this.props.listResults}
		});

		document.dispatchEvent(event);
	}

	render() {
		const loadMoreButton = (this.props.onLoadMore !== undefined && this.props.meta.hits > this.props.results.length) ? <button className="button loadMore-button" onClick={() => this.handleLoadMore()}><span className="icon-reload" aria-hidden="true"></span>Mehr anzeigen</button> : null;

		return (
			<div className="results">

				<ul className={this.props.listResults ? "listview" : "masonry"}>
					{this.props.results.map(result => {
						return (
							<Result key={result.id} result={result} />
						);
					})}
				</ul>

				{loadMoreButton}

			</div>
		);
	}
}
