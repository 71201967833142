import * as React from "react";

interface Props {
	result: any; // TODO: Type!
}

export default class Result extends React.Component<Props, any> {

	componentDidMount() {
	}

	render() {
		// TODO: Listenansicht

		const source = this.props.result;

		const image = (source.pictures.length > 0) ? <figure className="infoBox-image"><img className="infoBox-imageTag" src={source.pictures[0]+"?width=550"} alt={source.title} title={source.title} /></figure> : null;

		return (
			<li className="masonry-item infoBox-item artwork result bg-gray with-image">
				<a href={source.url}>
					<article className="infoBox-content">
						{image}
						<div className="infoBox-text hyphenate">
							<span className="icon-werk" aria-hidden="true"></span>
							<h2>{source.title}</h2>
							<p>{source.artist}</p>
							<span className="icon-arrow" aria-hidden="true"></span>
						</div>
					</article>
				</a>
			</li>
		)
	}
}
