import * as React from "react";
import SuggestionsList, {Suggestions} from "./SuggestionsList";
import {FormEvent} from "react";

const debounce = require('lodash.debounce');

interface Props {
	query?: string;
	loadSuggestions?: LoadSuggestionsCallback;
	onSubmit: OnSearchSubmittedCallback;
}

interface State {
	query: string;
	suggestions: Suggestions
}

export interface OnSearchSubmittedCallback {
	(query: string, event?: FormEvent<HTMLFormElement>): void;
}

export interface LoadSuggestionsCallback {
	(query: string): Promise<Suggestions>
}

export default class QueryBar extends React.Component<Props, State> {

	constructor(props: Props) {
		super(props);

		this.state = {
			query: props.query || '',
			suggestions: null,
		};
	}

	onFormSubmitted(event: FormEvent<HTMLFormElement>, query: string) {
		this.setState({
			suggestions: null,
		});
		this.bubbleSubmit(query, event);
	}

	bubbleSubmit(query: string, event?: FormEvent<HTMLFormElement>) {
		if (this.props.onSubmit) {
			this.props.onSubmit(query, event);
		}
	}

	onQueryChanged(query: string) {
		(debounce((query: string) => {
			if (this.props.loadSuggestions !== undefined) {
				this.props.loadSuggestions(query).then(suggestions => {
					this.setState({
						suggestions: suggestions
					});
				});
			}
		}, 300))(query);

		this.setState({
			query: query,
		});
	}

	handleSuggestionSelect(newQuery: string) {
		this.setState({
			suggestions: null,
			query: newQuery
		}, () => {
			this.bubbleSubmit(newQuery);
		});
	}

	render() {
		const hasSuggestions = this.state.suggestions && (this.state.suggestions.artworks.length > 0 || this.state.suggestions.artists.length > 0);
		const suggestions = hasSuggestions ? <SuggestionsList suggestions={this.state.suggestions} onSuggestionSelect={(key) => this.handleSuggestionSelect(key)} /> : null;

		return (
			<form method="get" action="/suche" className="search-form" onSubmit={(event) => this.onFormSubmitted(event, this.state.query)}>
				<fieldset>
					<input aria-label="Suchbegriff eingeben" placeholder="Jetzt suchen & finden" type="search" name="query"
						   className="search-input" value={this.state.query} autoComplete="off"
						   onChange={(event) => this.onQueryChanged(event.target.value)} />
					{suggestions}

					<button className="search-button" type="submit" value="Suchen" title="Suchen"><span
						className="icon-search" aria-hidden="true"></span></button>
				</fieldset>
			</form>
		)
	}
}
