import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./components/App";
import SearchService from "./services/SearchService";
import SuggestApp from "./components/SuggestApp";
import "custom-event-polyfill";

export interface Options {
	element: HTMLElement;
	endpoint: string;
}

export default {
	search: function (options: Options) {

		const searchService = new SearchService(options.endpoint);

		ReactDOM.render(<App searchService={searchService} />, options.element);
	},

	suggest: function (options: Options) {

		const searchService = new SearchService(options.endpoint);

		ReactDOM.render(<SuggestApp searchService={searchService} />, options.element);
	}

}
